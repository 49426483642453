.field {
  width: 130px;
  height: 130px;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  vertical-align: top;
}

@media (max-width: 1230px) {
  .field {
    width: 100px;
    height: 100px;
  }
  .field svg {
    font-size: 20px !important;
  }
}

.field .terrain {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.field .bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  z-index: 1;
}

.field:hover .terrain {
  opacity: 0.6;
}

.field.wasser .terrain {
  background-image: url("./assets/Wasser.jpg");
}

.field.gras .terrain {
  background-image: url("./assets/Feld.jpg");
}

.field.holz .terrain {
  background-image: url("./assets/Wald.jpg");
}

.field.stein .terrain {
  background-image: url("./assets/Stein.jpg");
}

.field.erz .terrain {
  background-image: url("./assets/Erz.jpg");
}

.field.getreide .terrain {
  background-image: url("./assets/Getreide.jpg");
}

.field.hidden .bg {
  background-color: black;
}

.field.active {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  z-index: 100;
}

.field.active .terrain {
  opacity: 0.9;
}

/* latin */
@font-face {
  font-family: "Comic Neue";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Comic Neue Light"), local("ComicNeue-Light"),
    url(https://fonts.gstatic.com/s/comicneue/v1/4UaErEJDsxBrF37olUeD_wHL8pxULg.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Comic Neue";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Comic Neue Regular"), local("ComicNeue-Regular"),
    url(https://fonts.gstatic.com/s/comicneue/v1/4UaHrEJDsxBrF37olUeD96rp5w.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: "Comic Neue";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Comic Neue Bold"), local("ComicNeue-Bold"),
    url(https://fonts.gstatic.com/s/comicneue/v1/4UaErEJDsxBrF37olUeD_xHM8pxULg.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

body,
* {
  font-family: "Comic Neue";
}

a,
a:visited {
  color: darkblue;
  text-decoration: none;
  opacity: 0.8;
}

a:hover {
  text-decoration: underline;
}
